import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsTwitter, BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { SiLinkedin } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import { useSpring, animated } from "react-spring";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import AboutBackgroundImage from "./Assets/about-background-image.png";
import AboutBackground from "./Assets/about-background.png";
import ChooseMeals from "./Assets/choose-image.png";
import DeliveryMeals from "./Assets/delivery-image.png";
import BannerImage from "./Assets/home-banner-image.png";
import vishesh from "../images/vishesh.jpg";
import kai from "../images/kai.png";
import logo from "../images/homePicture.png";
import growX from "../images/growx-logo.png";
import courseImage from "../images/courseImage.png";

import "./Home.css";
import { toast } from "react-toastify";
import { ArrowRight } from "@mui/icons-material";

const Home = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [users, setUsers] = useState(160);
  const [fail, setFail] = useState(false);
  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Simple email validation regex
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setError(!isValidEmail);
  };

  const handleWaitlist = () => {
    if (error || email == "") {
      toast.error("Please enter a valid email address.");
    } else {
      toast.success(
        "Thank you for joining the waitlist! We'll notify you when we launch."
      );
      setEmail("");
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const workInfoData = [
    {
      image: vishesh,
      title: "Vishesh Verma",
      text: "The junior class president at California High School. As an experienced coder and founder of an AI based education platform, he is motivated to help others learn and grow.",
      skills: [
        "All K-9 Subjects",
        "Computer Science",
        "Leadership",

        "Higher Level Math",
        "English",
        "Science",
      ],
    },
    {
      image: kai,
      title: "Kai Licata",
      text: "A hardworking, skilled and driven individual who has experience in teaching and helping kids learn. She is currently the junior class treasurer at California High school and is ready to guide others and help them grow.",
      skills: [
        "All K-9 Subjects",
        "Physics",
        "Higher Level Math",
        "Science",
        "English",
        "Leadership",
      ],
    },
  ];

  return (
    <Box
      sx={{
        px: 5,
      }}
    >
      <animated.div className="home-container">
        <motion.div className="home-banner-container">
          <div className="home-bannerImage-container">
            <img src={BannerImage} alt="" style={{ marginTop: -15 }} />
          </div>
          <div className="home-text-section">
            <h1 className="primary-heading">The Best Tutor For Your Child</h1>
            <p className="primary-text">
              Have your child be mentored and tutored by the highest quality
              tutors offered at no extra cost
            </p>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <TextField
                sx={{
                  borderTopLeftRadius: 10,
                  width: "20vw",
                  borderBottomLeftRadius: 10,
                  "& fieldset": {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "56px", // Match the button height
                  },
                  "& .MuiFormHelperText-root": {
                    position: "absolute",
                    bottom: "-20px", // Adjust positioning of the helper text
                  },
                }}
                placeholder="Email"
                type="email"
                value={email}
                onChange={handleChange}
                error={error}
                helperText={error ? "Please enter a valid email" : ""}
              /> */}
              <Button
                sx={{ pX: 2, py: 1 }}
                variant="contained"
                onClick={() => navigate("/connect-child")}
              >
                Connect Your Child With Us
                <ArrowRight />
              </Button>
            </Box>
          </div>
          <div className="home-image-section">
            <img src={logo} alt="logo" />
          </div>
        </motion.div>
      </animated.div>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="about-section-container"
          data-aos="fade-up"
          style={{
            display: "flex",
            alignItems: "center",
            // paddingLeft: 10,
            // paddingRight: 10,
          }}
        >
          <div className="">
            <img src={courseImage} alt="" style={{}} />
          </div>
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p className="primary-subheading">Why Us</p>
            <h1 className="primary-heading">Learning Is Vital</h1>
            <p className="primary-text">
              On our platform, we connect you with experienced and knowledgeable
              tutors who are experts in their respective fields. Our tutors are
              dedicated to helping you achieve your academic goals by offering
              personalized guidance and support.
            </p>
            <p className="primary-text">
              Whether you’re struggling with a particular subject or looking to
              deepen your understanding, our qualified tutors provide one-on-one
              sessions tailored to your unique learning style and pace. With a
              focus on clarity and engagement, they ensure that every concept is
              understood and every question is answered, empowering you to excel
              in your studies.
            </p>
          </div>
        </div>
      </Box>
      <div
        className="work-section-wrapper"
        data-aos="fade-up"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="work-section-top">
          <p className="primary-subheading">Tutors</p>
          <h1 className="primary-heading">Our Qualified Tutors</h1>
          <p className="primary-text">
            We offer expert tutors who provide personalized support to help you
            excel. With one-on-one guidance, they ensure you fully understand
            key concepts and stay on track in your learning journey.
          </p>
        </div>
        <div className="work-section-bottom">
          {workInfoData.map((data) => (
            <Card
              className="work-section-info"
              key={data.title}
              sx={{ cursor: "pointer" }}
            >
              <div
                className="info-boxes-img-container"
                style={{
                  width: "150px", // Set the desired size for the image
                  height: "150px", // Same height and width for a perfect circle
                  overflow: "hidden", // Ensure the image doesn't overflow
                  borderRadius: "50%", // Create the circular shape
                  margin: "0 auto", // Center the image
                }}
              >
                <img
                  src={data.image}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // Ensures the image fills the container properly
                  }}
                />
              </div>
              <h2>{data.title}</h2>
              <p>{data.text}</p>
              <Box sx={{ display: "flex", mt: 2 }}>
                {data?.skills?.slice(0, 3).map((skill) => (
                  <Grid item xs="auto">
                    <Chip label={skill} variant="outlined" />
                  </Grid>
                ))}
              </Box>

              <Box sx={{ display: "flex" }}>
                {data?.skills?.slice(3, 6).map((skill) => (
                  <Grid item xs="auto">
                    <Chip label={skill} variant="outlined" />
                  </Grid>
                ))}
              </Box>
            </Card>
          ))}
        </div>
        <Button
          variant="contained"
          sx={{ mt: 1 }}
          onClick={() => navigate("/connect-child")}
        >
          Connect Your Child Now
        </Button>
      </div>
      <div className="footer-wrapper">
        {/* <div className="footer-section-one">
          <div className="footer-logo-container">
            <img src={growX} />
          </div>
          <div className="footer-icons">
            <BsTwitter />
            <SiLinkedin />
            <BsYoutube />
            <FaFacebookF />
          </div>
        </div>
        <div className="footer-section-two"></div> */}
      </div>
    </Box>
  );
};

export default Home;
