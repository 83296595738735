// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBedQgxLW1ihnFnZwFPnSlSJPdcXoco22Q",
  authDomain: "vkx-tutors.firebaseapp.com",
  projectId: "vkx-tutors",
  storageBucket: "vkx-tutors.appspot.com",
  messagingSenderId: "821638580834",
  appId: "1:821638580834:web:c12b83b0d1318285b99f3b",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
