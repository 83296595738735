import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Stepper,
  Card,
  Fade,
  Autocomplete,
  FormControl,
  FormHelperText,
  CardHeader,
  Chip,
  Divider,
  CardContent,
  LinearProgress,
  Step,
  StepLabel,
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Slider,
} from "@mui/material";
import { Slide } from "@mui/material";
import { AutoAwesome } from "@mui/icons-material";
import { useSelector } from "react-redux";
import logo from "./Assets/Processing.png";
import { useNavigate } from "react-router";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";
import { toast } from "react-toastify";

const steps = ["Child Information", "Guardian Information", "Finalize"];

const loadingMessages = [
  "Connecting you with a tutor...",
  "Sending your information...",
];

const gradeLevels = [
  "Preschool",
  "Kindergarten",
  "1st Grade",
  "2nd Grade",
  "3rd Grade",
  "4th Grade",
  "5th Grade",
  "6th Grade",
  "7th Grade",
  "8th Grade",
  "9th Grade (Freshman)",
  "10th Grade (Sophomore)",
  "11th Grade (Junior)",
  "12th Grade (Senior)",
];

const prefered = ["Phone Number", "Email"];

const ConnectChild = () => {
  const { user } = useSelector((state) => state.auth);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    grade: gradeLevels[0],
    gName: "",
    gEmail: "",
    gPhone: "",
    gPrefered: prefered[0],
    help: [],
  });
  const [sliding, setSliding] = useState(true);
  const [slideDirection, setSlideDirection] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [emailError, setEmailError] = useState(false); // Add state for email error
  const [phoneError, setPhoneError] = useState(false); // Add state for phone error
  const navigate = useNavigate();
  const slideRef = useRef();

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        const nextMessage =
          loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
        setLoadingText(nextMessage);
      }, 2000);
      return () => clearInterval(intervalId);
    }
  }, [loading]);

  const validateEmail = (email) => {
    // Basic email validation regex
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    // Basic phone number validation regex (accepts only digits and a minimum of 10 characters)
    const re = /^\d{10}$/;
    return re.test(String(phone));
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      setSliding(false);
      setTimeout(() => {
        submitProfileData();
      }, 300);
    } else {
      setSlideDirection("next");
      setSliding(false);
      setTimeout(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSliding(true);
      }, 300);
    }
  };

  const handleBack = () => {
    setSlideDirection("back");
    setSliding(false);

    setTimeout(() => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSliding(true);
    }, 350);
  };

  const submitProfileData = async () => {
    const name = formData.name;
    const grade = formData.grade;
    const gName = formData.gName;
    const gEmail = formData.gEmail;
    const gPhone = formData.gPhone;
    const gPrefered = formData.gPrefered;
    const help = formData.help;
    setLoading(true);
    setSubmitted(true);
    try {
      const docRef = await addDoc(collection(db, "children"), {
        name: name,
        grade: grade,
        help: help,
        guardian: {
          name: gName,
          email: gEmail,
          phone: gPhone,
          prefered: gPrefered,
        },
      });
    } catch (e) {
      toast.error("Error sending profile information. Please retry.");
      setSubmitted(false);
    }

    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the email is valid and update the error state
    if (name === "gEmail") {
      setEmailError(!validateEmail(value));
    }

    // Check if the phone number is valid and update the error state
    if (name === "gPhone") {
      setPhoneError(!validatePhone(value));
    }

    setFormData({ ...formData, [name]: value });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              label="Child Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              sx={{ mb: 1 }}
              fullWidth
            />
            <TextField
              select
              sx={{ mt: 2 }}
              label="Grade Level"
              name="grade"
              value={formData.grade}
              onChange={handleChange}
              margin="auto"
              fullWidth
            >
              {gradeLevels.map((grade, index) => (
                <MenuItem key={index} value={index}>
                  {grade}
                </MenuItem>
              ))}
            </TextField>
            <FormControl margin="normal" fullWidth sx={{ mt: 3 }}>
              <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                value={formData.help}
                onChange={(e, newValue) =>
                  setFormData({ ...formData, help: newValue })
                }
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        variant="outlined"
                        label={option}
                        key={key}
                        {...tagProps}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="What Help Is Need"
                    placeholder="What does your child need help on?"
                  />
                )}
              />
              <FormHelperText>
                {" "}
                <Typography variant="caption">
                  <strong>Pro tip:</strong> Press{" "}
                  <span
                    style={{
                      padding: "0px 4px",
                      borderRadius: "2px",
                      backgroundColor: "rgb(223, 225, 230)",
                      color: "rgb(23, 43, 77)",
                      margin: "0 4px",
                      fontWeight: 700,
                      fontSize: "12px",
                    }}
                  >
                    Enter
                  </span>{" "}
                  to add subjects your child needs help on.
                </Typography>
              </FormHelperText>
            </FormControl>
          </>
        );
      case 1:
        return (
          <>
            <TextField
              label="Guardian Name"
              name="gName"
              value={formData.gName}
              onChange={handleChange}
              sx={{ mb: 1 }}
              fullWidth
            />
            <TextField
              label="Guardian Email"
              name="gEmail"
              value={formData.gEmail}
              onChange={handleChange}
              sx={{ mb: 1, mt: 2 }}
              fullWidth
              error={emailError} // Error prop to turn field red
              helperText={
                emailError ? "Please enter a valid email address." : ""
              }
            />
            <TextField
              label="Guardian Phone Number"
              name="gPhone"
              value={formData.gPhone}
              onChange={handleChange}
              sx={{ mt: 2, mb: 1 }}
              fullWidth
              error={phoneError} // Error prop to turn field red
              helperText={
                phoneError
                  ? "Please enter a valid phone number (10 digits)."
                  : ""
              }
            />
            <TextField
              select
              sx={{ mt: 2 }}
              label="Preferred Method Of Contact"
              name="gPrefered"
              value={formData.gPrefered}
              onChange={handleChange}
              margin="auto"
              fullWidth
            >
              {prefered.map((method, index) => (
                <MenuItem key={index} value={method}>
                  {method}
                </MenuItem>
              ))}
            </TextField>
          </>
        );
      case 2:
        return (
          <>
            <TextField
              label="Child Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              sx={{ mb: 1 }}
              fullWidth
            />
            <TextField
              select
              sx={{ mt: 2 }}
              label="Grade Level"
              name="grade"
              value={formData.grade}
              onChange={handleChange}
              margin="auto"
              fullWidth
            >
              {gradeLevels.map((grade, index) => (
                <MenuItem key={index} value={index}>
                  {grade}
                </MenuItem>
              ))}
            </TextField>
            <FormControl margin="normal" fullWidth sx={{ mt: 3 }}>
              <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                value={formData.help}
                onChange={(e, newValue) =>
                  setFormData({ ...formData, help: newValue })
                }
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        variant="outlined"
                        label={option}
                        key={key}
                        {...tagProps}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="What Help Is Need"
                    placeholder="What does your child need help on?"
                  />
                )}
              />
              <FormHelperText>
                {" "}
                <Typography variant="caption">
                  <strong>Pro tip:</strong> Press{" "}
                  <span
                    style={{
                      padding: "0px 4px",
                      borderRadius: "2px",
                      backgroundColor: "rgb(223, 225, 230)",
                      color: "rgb(23, 43, 77)",
                      margin: "0 4px",
                      fontWeight: 700,
                      fontSize: "12px",
                    }}
                  >
                    Enter
                  </span>{" "}
                  to add subjects your child needs help on.
                </Typography>
              </FormHelperText>
            </FormControl>

            <TextField
              label="Guardian Name"
              name="gName"
              value={formData.gName}
              onChange={handleChange}
              sx={{ mb: 1, mt: 3 }}
              fullWidth
            />
            <TextField
              label="Guardian Email"
              name="gEmail"
              value={formData.gEmail}
              onChange={handleChange}
              sx={{ mb: 1, mt: 2 }}
              fullWidth
              error={emailError} // Error prop to turn field red
              helperText={
                emailError ? "Please enter a valid email address." : ""
              }
            />
            <TextField
              label="Guardian Phone Number"
              name="gPhone"
              value={formData.gPhone}
              onChange={handleChange}
              sx={{ mt: 2, mb: 1 }}
              fullWidth
              error={phoneError} // Error prop to turn field red
              helperText={
                phoneError
                  ? "Please enter a valid phone number (10 digits)."
                  : ""
              }
            />
            <TextField
              select
              sx={{ mt: 2 }}
              label="Preferred Method Of Contact"
              name="gPrefered"
              value={formData.gPrefered}
              onChange={handleChange}
              margin="auto"
              fullWidth
            >
              {prefered.map((method, index) => (
                <MenuItem key={index} value={method}>
                  {method}
                </MenuItem>
              ))}
            </TextField>
          </>
        );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      ref={slideRef}
    >
      <Box sx={{ width: "60%", margin: "auto" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <br />
        {!submitted ? (
          <Slide
            direction={
              slideDirection === "next" && sliding
                ? "right"
                : slideDirection === "back" && !sliding
                ? "right"
                : "left"
            }
            in={sliding}
            container={slideRef.current}
            mountOnEnter
            unmountOnExit
          >
            <Card sx={{ mt: 2 }}>
              <CardContent sx={{ p: 3 }}>
                <CardHeader
                  sx={{ padding: 0 }}
                  title={steps[activeStep]}
                  subheader=""
                />
                <Divider sx={{ margin: (theme) => theme.spacing(2, -3, 4) }} />
                {getStepContent(activeStep)}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 4,
                  }}
                >
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={
                      (activeStep === 0 &&
                        (!formData.name ||
                          !formData.grade ||
                          formData.help.length == 0)) ||
                      (activeStep === 1 &&
                        (emailError ||
                          phoneError ||
                          !formData.gName ||
                          !formData.gEmail ||
                          !formData.gPrefered ||
                          !formData.gPhone))
                    }
                  >
                    {activeStep === steps.length - 1 ? "Submit" : "Next"}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Slide>
        ) : (
          <>
            {loading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 4,
                }}
              >
                <img src={logo} alt="Loading" width={250} height={250} />
                <Typography variant="h5" align="center">
                  {loadingText}
                </Typography>
                <LinearProgress sx={{ mt: 4, width: "60%" }} />
              </Box>
            ) : (
              <Fade in={!loading}>
                <Card
                  sx={{
                    maxWidth: 600,
                    margin: "0 auto",
                    p: 3,
                    borderRadius: 3,
                    boxShadow: "0 8px 16px rgba(0,0,0,0.1)", // Subtle shadow for depth
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        mb: 2,
                        fontWeight: 700,
                      }}
                    >
                      Sent your information! 🎉
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        mb: 1,
                        fontWeight: 600,
                        color: "#919EAB",
                        textTransform: "capitalize",
                      }}
                    >
                      <strong style={{ color: "white" }}>Child Name:</strong>{" "}
                      {formData.name}
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        mb: 1,
                        fontWeight: 600,
                        color: "#919EAB",
                        textTransform: "capitalize",
                      }}
                    >
                      <strong style={{ color: "white" }}>Child Grade:</strong>{" "}
                      {formData.grade - 1}th
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        mb: 1,
                        fontWeight: 600,
                        color: "#919EAB",
                        textTransform: "capitalize",
                      }}
                    >
                      <strong style={{ color: "white" }}>Guardian Name:</strong>{" "}
                      {formData.gName}
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        mb: 1,
                        fontWeight: 600,
                        color: "#919EAB",
                      }}
                    >
                      <strong style={{ color: "white" }}>
                        Guardian Email:
                      </strong>{" "}
                      {formData.gEmail}
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        mb: 1,
                        fontWeight: 600,
                        color: "#919EAB",
                        textTransform: "capitalize",
                      }}
                    >
                      <strong style={{ color: "white" }}>
                        Guardian Phone:
                      </strong>{" "}
                      {formData.gPhone}
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        mb: 1,
                        fontWeight: 600,
                        color: "#919EAB",
                        textTransform: "capitalize",
                      }}
                    >
                      <strong style={{ color: "white" }}>
                        Preferred Method of Contact:
                      </strong>{" "}
                      {formData.gPrefered}
                    </Typography>

                    <Divider sx={{ width: "100%", my: 2 }} />

                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      We will contact you soon for more information. Please wait
                      patiently. Thank you.
                    </Typography>
                  </CardContent>
                </Card>
              </Fade>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
export default ConnectChild;
